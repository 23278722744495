/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import Main from "../components/Main";
import Container from "../components/Container";
import Title from "../components/Title";
import SEO from "../components/SEO";

export default ({ data }) => {
  const e = data.markdownRemark;
  return (
    <Main>
      <SEO title="Page two" />

      <Container>
        <Title
          titleName={e.frontmatter.uberschrift}
          titleSub={e.frontmatter.unteruberschrift}
          titleLink={e.frontmatter.link_name}
          titleURL={e.frontmatter.link_url}
        />
        <ReactMarkdown>{e.rawMarkdownBody}</ReactMarkdown>
      </Container>
    </Main>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      #html
      frontmatter {
        uberschrift
        unteruberschrift
        link_name
        link_url
        # seo
      }
      rawMarkdownBody
    }
  }
`;

// /** @jsx jsx */
// import { Styled, jsx } from "theme-ui";

// import Main from "../components/Main";
// import SEO from "../components/SEO";
// import Title from "../components/Title";
// import Container from "../components/Container";

// export default () => (
//   <Main>
//     {/* TODO: Add SEO to page */}
//     <SEO title="Page" />

//     <Container>
//       <Title
//         titleName="Über ein Jahrhundert Erfahrung, damit Sie zu Ihrem Recht kommen."
//         titleSub="Unsere Rechtsgebiete"
//       />

//     </Container>
//   </Main>
// );
